import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import Link from '../../../core/Components/Link';

class ContactUsDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeContent: null,
      preSelected: null,
    };
  }

  componentDidMount() {
    const dropdownKey = localStorage.getItem('dropdownKey');
    if (!!dropdownKey) {
      this.setState({ preSelected: dropdownKey }, () =>
        this.handleChange(dropdownKey),
      );
      localStorage.removeItem('dropdownKey');
    }
  }

  handleChange = (e) => {
    const { fields } = this.props;
    const defaultOption = { fields: null };
    const newData =
      typeof e === 'string'
        ? (fields.options.find((option) => option.id === e) || defaultOption)
            .fields
        : (
            fields.options.find((option) => option.id === e.target.value) ||
            defaultOption
          ).fields;
    const content = !!newData ? (
      <React.Fragment>
        <RichText field={newData.description} />
        {!!newData.contact.value && (
          <p className="phone">
            <a href={`tel:${newData.contact.value}`}>
              <Text field={newData.contact} />
            </a>
            <Text field={newData.contactHelp} tag="span" />
          </p>
        )}
        {!!newData.contactSecondary.value && (
          <p className="phone">
            <a href={`tel:${newData.contactSecondary.value}`}>
              <Text field={newData.contactSecondary} />
            </a>
            <Text field={newData.contactSecondaryHelp} tag="span" />
          </p>
        )}
        {!!newData.email.value && (
          <p className="email">
            <a href={`mailto:${newData.email.value}`}>
              <Text field={newData.email} />
            </a>
          </p>
        )}
        {typeof newData.link.value.text !== 'undefined' && (
          <Link
            field={newData.link}
            className="cta cta-button"
            onClick={() => {
              if (!!newData.option) {
                localStorage.setItem(
                  'formKey',
                  newData.option.fields.Value.value,
                );
              }
            }}
          />
        )}
      </React.Fragment>
    ) : null;

    this.setState({
      activeContent: content,
      ...(!!newData ? {} : { preSelected: null }), // if newData does not exists, change preSelected to null
    });
  };

  render() {
    const { activeContent, preSelected } = this.state;
    const { fields } = this.props;

    return (
      <div className="contact-us-dropdown form-dropdown-box">
        <Text field={fields.title} tag="h2" />
        <form>
          <label htmlFor="dropdown">
            <Text field={fields.description} />
          </label>
          <select
            className="select dropdown"
            id="dropdown"
            onChange={this.handleChange}
          >
            {!preSelected && (
              <option selected disabled>
                {fields.placeholder.value}
              </option>
            )}
            {fields.options.map((option) => {
              return !!preSelected ? (
                <option
                  key={option.id}
                  selected={option.id === preSelected}
                  value={option.id}
                >
                  {option.fields.title.value}
                </option>
              ) : (
                <option key={option.id} value={option.id}>
                  {option.fields.title.value}
                </option>
              );
            })}
          </select>
        </form>
        {activeContent}
      </div>
    );
  }
}

export default ContactUsDropdown;
